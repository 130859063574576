import '../layouts/tachyons/css/tachyons.css';
import '../layouts/custom.css';
import styled from 'styled-components'

const CentererWide = styled.div.attrs({
  className: 'center mw9',
})`
&.centerer-var-nomaxwidth{
  max-width:100%;
  padding:0px 0px;
}
@media (max-width: 30em) {
  &.centerer-var-nomaxwidth{
    max-width:100%;
    padding:0px 8px;
  }
}

@media (min-width: 30em) and (max-width: 60em) {
  &.centerer-var-nomaxwidth{
    max-width:100%;
    padding:0px 0px;
  }
}

&.centerer-var-nomaxwidthimage{
  max-width:100%;
  padding:0px 0px;
}
&.centerer-var-fullheight{
   min-height:100vh;
   padding-top:64px;
   padding-bottom:64px;
}
&.withborder{
  border-bottom-width:1px;
  border-bottom-style:solid;
}
&.quote{
  background-color: ${props => props.customBgColor || "#11151F"};
  color: ${props => props.customTextColor || "white"};
}
`;

const CentererNarrow = styled.div.attrs({
  className: 'center mw9',
})`
&.centerer-var-nomaxwidth{
  max-width:100%;
}
&.withborder{
  border-bottom-width:1px;
  border-bottom-style:solid;
}
`;

const CentererNarrowSubs = styled.div.attrs({
  className: 'center mw8',
})`
&.centerer-var-nomaxwidth{
  max-width:100%;
}
&.withborder{
  border-bottom-width:1px;
  border-bottom-style:solid;
}
`;

const Wrapper = styled.div.attrs({
  className: 'ph0-l ph32-m ph16 mh0-l mh0-m mh0',
})`
`;

const WrapperSubs = styled.div.attrs({
  className: '',
})`
`;


const WrapperTab = styled.div.attrs({
  className: 'ph24-l ph8-m ph8 mh16-l mh0-m mh0',
})`
`;

const Divider = styled.hr.attrs({
  className: 'bb',
})`
background:none;
`; 

const DividerPost = styled.hr.attrs({
  className: 'bb mt32 mb32',
})`
background:none;
opacity:0.1;
border-color: ${props => props.customBrColor || "#fff"};
`; 

const InitialDiv = styled.div.attrs({
  className: '',
})`
  background-color: ${props => props.customBgColor || "#11151F"};
`;

export { CentererWide, CentererNarrow, CentererNarrowSubs,  Wrapper, WrapperSubs, WrapperTab, Divider, DividerPost, InitialDiv}
