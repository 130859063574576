import '../layouts/tachyons/css/tachyons.css';
import '../layouts/custom.css';
import styled from 'styled-components'

const InterExtra = styled.h1.attrs({
    className: 'inter f-subheadline-l f2-m mb0 mt0 qn-lh-condensed qn-ls-condensed',
  })`
  `;   

const InterLarge = styled.h2.attrs({
    className: ' inter f4-l f3 mb0 mt0 qn-lh-tight qn-ls-condensed',
  })`
  &.list{
  color: ${props => props.customTextColor || "#ffffff"};
  }
  `; 

const InterMedium = styled.p.attrs({
    className: 'inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight',
  })`
  &.link a:link, .link a:visited{
    color:#fd6062;
  }
  &.link a:hover{
    color:#fff;
  }
  `;
  
const IbmMedium = styled.p.attrs({
    className: 'ibmplexmono f6-l f6 mb0 mt0 qn-lh-normal qn-ls-normal',
  })`
  `;
  
const IbmSmall = styled.small.attrs({
    className: 'ibmplexmono f7-l f7 mb0 mt0 qn-lh-normal qn-ls-normal',
})`
`;


export { InterExtra, InterLarge, InterMedium, IbmMedium, IbmSmall }
