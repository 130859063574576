import React from "react"
import '../layouts/tachyons/css/tachyons.css';
import '../layouts/custom.css';
import { InterLarge, InterMedium} from "../components/typography"
import { CentererWide, CentererNarrow, Wrapper, WrapperSubs, Divider} from "../components/section"
import addToMailchimp from 'gatsby-plugin-mailchimp'
import CarbonAd from "./carbonad";

class SubscribeNarrow extends React.Component {
  state = {
      name: null,
      email: null,
  }

  _handleChange = (e) => {
      console.log({
          [`${e.target.name}`]: e.target.value,
      });
      this.setState({
          [`${e.target.name}`]: e.target.value,
      });
  }

  _handleSubmit = (e) => {
      e.preventDefault();

      console.log('submit', this.state);

      addToMailchimp(this.state.email, {
          FNAME: this.state.name
        }, this.state)
          .then(({ msg, result }) => {
              console.log('msg', `${result}: ${msg}`);

              if (result !== 'success') {
                  throw msg;
              }
              alert(msg);
          })
          .catch((err) => {
              console.log('err', err);
              alert(err);
          });
  }

  render() {
      return (
        <div className="pt24-ns pb8-ns pt8 pb16 qn-bg-dark1 mw9 ph32-l ph32-m ph24 center">
          <a className="w-100 mb32-l mb24-m mb24 br3 f5 pointer dib qn-transition-bg qn-BtnSecondarySubs mw9" href="/"><InterMedium className="fw6 tc">See more quotes</InterMedium></a>
          <Divider className="qn-br-dark2 mb16"/>
          <CentererNarrow className="flex-l items-center-l qn-cl-white qn-bg-dark1 pt16-l pb16-l">
              <WrapperSubs className="w-70-l w-100 mb24"> 
                <InterLarge className="fw7 mb8">Subscribe for weekly UI &amp; UX highlights!</InterLarge>
                <p className="ibmplexmono f6-l f6 mb0 mt0 qn-ls-normal fw3 qn-lh-squeeze mb24">I promise, I won't spam you.</p>
                <div className="w-100 flex items-center mt16">
                  <div className="db w-100-l w-100-m">
                      <form onSubmit={this._handleSubmit}>
                        <div className="fl pr8-ns w-40-l w-40-m w-100 mb0-ns mb8">
                          <input className="w-100 qn-form-field qn-cl-dark1 qn-transition-al f6 br2 pt8 pb8 pl16 pr8 inter" type="text" onChange={this._handleChange} placeholder="Your name" name="name"/>
                        </div>
                        <div className="fl pr8-ns w-40-l w-40-m w-100 mb0-ns mb8">
                          <input className="w-100 qn-form-field qn-cl-dark1 qn-transition-al f6 br2 pt8 pb8 pl16 pr8 inter" type="email" onChange={this._handleChange} placeholder="Your email" name="email"/>
                        </div>
                        <div className="fl w-20-l w-20-m w-40">
                          <input class="w-100 qn-btn-primary qn-cl-white qn-transition-al f6 fw6 br2 pt8 pb8 pl8 pr8 inter" type="submit" value="Subscribe" />
                        </div>
                      </form>
                  </div>
                </div>
              </WrapperSubs>
              <WrapperSubs className="w-30-l w-100 nt3 pt0-l pt24 mb0-l mb24">
                <div className="pl24-l">
                  {/*<CarbonAds 
                      carbonUrl="//cdn.carbonads.com/carbon.js?serve=CE7DL2JI&placement=insightshuntcom"
                  />*/}
                  <CarbonAd/>
                </div>
              </WrapperSubs>
          </CentererNarrow>  
        </div>
      );
    }
  }


class SubscribeOnly extends React.Component {
    state = {
        name: null,
        email: null,
    }
  
    _handleChange = (e) => {
        console.log({
            [`${e.target.name}`]: e.target.value,
        });
        this.setState({
            [`${e.target.name}`]: e.target.value,
        });
    }
  
    _handleSubmit = (e) => {
        e.preventDefault();
  
        console.log('submit', this.state);
  
        addToMailchimp(this.state.email, {
            FNAME: this.state.name
          }, this.state)
            .then(({ msg, result }) => {
                console.log('msg', `${result}: ${msg}`);
  
                if (result !== 'success') {
                    throw msg;
                }
                alert(msg);
            })
            .catch((err) => {
                console.log('err', err);
                alert(err);
            });
    }
  
    render() {
        return (
          <div>
            <CentererNarrow className="flex-l items-center-l qn-cl-white qn-bg-dark1 pt16-l pb16-l">
                <WrapperSubs className="w-100-l w-100 mb24 pr0-l"> 
                  <div className="w-100 flex items-center">
                    <div className="db w-100-l w-100-m">
                        <form onSubmit={this._handleSubmit}>
                          <div className="fl pr8-ns w-40-l w-40-m w-100 mb0-ns mb8">
                            <input className="w-100 qn-form-field qn-cl-dark1 qn-transition-al f6 br2 pt8 pb8 pl16 pr8 inter" type="text" onChange={this._handleChange} placeholder="Your name" name="name"/>
                          </div>
                          <div className="fl pr8-ns w-40-l w-40-m w-100 mb0-ns mb8">
                            <input className="w-100 qn-form-field qn-cl-dark1 qn-transition-al f6 br2 pt8 pb8 pl16 pr8 inter" type="email" onChange={this._handleChange} placeholder="Your email" name="email"/>
                          </div>
                          <div className="fl w-20-l w-20-m w-40">
                            <input class="w-100 qn-btn-primary qn-cl-white qn-transition-al f6 fw6 br2 pt8 pb8 pl8 pr8 inter" type="submit" value="Subscribe" />
                          </div>
                        </form>
                    </div>
                  </div>
                </WrapperSubs>
            </CentererNarrow>  
          </div>
        );
      }
}

  class SubscribeWide extends React.Component {
    state = {
        name: null,
        email: null,
    }
  
    _handleChange = (e) => {
        console.log({
            [`${e.target.name}`]: e.target.value,
        });
        this.setState({
            [`${e.target.name}`]: e.target.value,
        });
    }
  
    _handleSubmit = (e) => {
        e.preventDefault();
  
        console.log('submit', this.state);
  
        addToMailchimp(this.state.email, {
            FNAME: this.state.name
          }, this.state)
            .then(({ msg, result }) => {
                console.log('msg', `${result}: ${msg}`);
  
                if (result !== 'success') {
                    throw msg;
                }
                alert(msg);
            })
            .catch((err) => {
                console.log('err', err);
                alert(err);
            });
    }
  
    render() {
        return (
          <div className="pt32-l pb48-l pt32 pb32 qn-bg-dark1 mw9 items-center center">
            <CentererWide className="flex items-center  qn-cl-white">
                <Wrapper className="w-100-ns mb32"> 
                  <a className="w-100 mb32 br2 f5 pointer dib qn-transition-bg qn-BtnSecondarySubs" href="/all/"><InterMedium className="fw6 tc">See All Quotes</InterMedium></a>
                  <Divider className="qn-br-dark2 mb24"/>
                  <InterLarge className="fw7 mb8">Subscribe for weekly UI &amp; UX highlights!</InterLarge>
                  <p className="ibmplexmono f6-l f6 mb0 mt0 qn-ls-normal fw3 qn-lh-squeeze mb24">I promise. I won't spam you. <span role="img" aria-label="emoji">🤝</span></p>
                  <div className="w-100 flex items-center mt16">
                    <div className="db w-100-l w-100-m">
                        <form onSubmit={this._handleSubmit}>
                          <div className="fl pr8-ns w-40-l w-40-m w-100 mb0-ns mb8">
                            <input className="w-100 qn-form-field qn-cl-dark1 qn-transition-al f6 br2 pt8 pb8 pl16 pr8 inter" type="text" onChange={this._handleChange} placeholder="Your name" name="name"/>
                          </div>
                          <div className="fl pr8-ns w-40-l w-40-m w-100 mb0-ns mb8">
                            <input className="w-100 qn-form-field qn-cl-dark1 qn-transition-al f6 br2 pt8 pb8 pl16 pr8 inter" type="email" onChange={this._handleChange} placeholder="Your email" name="email"/>
                          </div>
                          <div className="fl w-20-l w-20-m w-40">
                            <input class="w-100 qn-btn-primary qn-cl-white qn-transition-al f6 fw6 br2 pt8 pb8 pl8 pr8 inter" type="submit" value="Subscribe" />
                          </div>
                        </form>
                    </div>
                  </div>
                </Wrapper>
            </CentererWide>  
          </div>
        );
      }
    }

    export { SubscribeNarrow, SubscribeWide, SubscribeOnly };
    